import { SortOrder } from "consts";
import { FormikProps, FormikValues } from "formik";
import { Dispatch, SetStateAction } from "react";
import { TMspEduRequest } from "types/apis/edu-requests";

export enum RequestAction {
  APPROVE = "APPROVE",
  DECLINE = "DECLINE",
}

export type RequestActionFormModalProps = {
  closeModal: () => void;
  action: RequestAction;
  isOpen: boolean;
  selectedRequestId: string | null;
  setSelectedRequestId: Dispatch<SetStateAction<string | null>>;
};

export type RequestActionFormContainerProps = {
  formikProps: FormikProps<FormikValues>;
  action: RequestAction;
};

export type FilterListState = {
  searchQuery: string;
  product: number[];
  status: number[];
  sortOrder: SortOrder;
  sortBy: keyof TMspEduRequest;
};

export type ActionBarProps = {
  selectedRequestId: string | null;
  setSelectedRequestId: Dispatch<SetStateAction<string | null>>;
  setFilters: Dispatch<SetStateAction<FilterListState>>;
  filters: FilterListState;
};

export enum EduRequestStatus {
  NOTREQUESTED,
  PENDING,
  APPROVED,
  DECLINED,
}

export type RequestsListProps = {
  data: TMspEduRequest[];
  canFetchMore: boolean;
  isFetching: boolean;
  setSelectedRequestId: Dispatch<SetStateAction<string | null>>;
  selectedRowId: string | null;
  disabledRequests: string[];
  setFilters: Dispatch<SetStateAction<FilterListState>>;
  filters: FilterListState;
};
