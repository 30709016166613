import { FormikProps, FormikValues } from "formik";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";

import useUpdateEduRequest, {
  TUpdateRequestPayload,
} from "queryHooks/useUpdateEduRequest/useUpdateEduRequest";
import ModalForm from "shared/ModalForm";
import { showAlert } from "slices/alertsSlice";
import {
  EduRequestStatus,
  RequestAction,
  RequestActionFormModalProps,
} from "./edu-license-requests.types";
import RequestActionFormContainer from "./requests-action-form-container";

export const RequestActionFormModal: FC<RequestActionFormModalProps> = ({
  closeModal,
  action,
  isOpen,
  selectedRequestId,
  setSelectedRequestId,
}) => {
  const dispatch = useDispatch();

  const schemaValidator = useCallback(
    (Yup: any) => {
      const commentRequiredErrorMessage = `Enter a reason for ${
        action === RequestAction.APPROVE ? "approval" : "decline"
      } (Maximum 100 characters).`;
      const maxCommentLengthError =
        "Limit exceeded. Enter reason up to 100 characters.";
      const confirmationErrorMessage = `Enter '${
        action === RequestAction.APPROVE ? "Approve" : "Decline"
      }' to proceed further.`;

      return Yup.object().shape({
        comment: Yup.string()
          .required(commentRequiredErrorMessage)
          .max(100, maxCommentLengthError),
        confirmation: Yup.string()
          .matches(
            action === RequestAction.APPROVE ? /^Approve$/ : /^Decline$/,
            confirmationErrorMessage
          )
          .required(confirmationErrorMessage),
      });
    },
    [action]
  );
  const { mutate } = useUpdateEduRequest();

  const submitHandler = useCallback(
    (model: TUpdateRequestPayload) => {
      mutate(
        {
          comment: model.comment,
          id: selectedRequestId || "",
          status:
            action === RequestAction.APPROVE
              ? EduRequestStatus.APPROVED
              : EduRequestStatus.DECLINED,
        },
        {
          onSuccess: (data) => {
            setSelectedRequestId(null);
            if (data?.warning) {
              dispatch(
                showAlert({
                  message: data?.message,
                  type: "warning",
                })
              );
            } else {
              dispatch(
                showAlert({
                  message: `Successfully ${
                    action === RequestAction.APPROVE ? "approved" : "rejected"
                  } Education license request`,
                  type: "success",
                })
              );
            }
            closeModal();
          },
        }
      );
    },
    [mutate, selectedRequestId, action]
  );

  return (
    // @ts-expect-error - types not correct in orion
    <ModalForm
      disableSubmitWhenInvalid
      closeModal={closeModal}
      withValidation
      header={`${
        action === RequestAction.APPROVE ? "Approve" : "Decline"
      } a request`}
      isOpen={isOpen}
      size="lg"
      onSubmit={submitHandler}
      initialValues={{
        comment: "",
        confirmation: "",
      }}
      validationSchema={schemaValidator}
      renderChildren={(props: FormikProps<FormikValues>) => (
        <RequestActionFormContainer formikProps={props} action={action} />
      )}
      submitButtonLabel={
        action === RequestAction.APPROVE ? "Approve" : "Decline"
      }
    />
  );
};
