import Button from "orion/lib/button";
import Col from "orion/lib/col";
import FilterButton from "orion/lib/filterButton";
import Input from "orion/lib/input";
import Row from "orion/lib/row";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";

import { FormKey } from "consts";
import { openModal } from "slices/modalsSlice";
import { filterOptions } from "./requests.helper";
import styles from "./requests.module.scss";
import { ActionBarProps, RequestAction } from "./edu-license-requests.types";
import FilterRenderer from "./requests-filter-renderer";

const ActionBar: FC<ActionBarProps> = ({
  selectedRequestId,
  setSelectedRequestId,
  setFilters,
  filters,
}) => {
  const dispatch = useDispatch();

  const disabledAction = !selectedRequestId;

  const handleActionClick = useCallback(
    (action: RequestAction) => () => {
      dispatch(
        openModal({
          key: FormKey.EDU_REQUEST_ACTION,
          action,
          selectedRequestId,
          setSelectedRequestId,
        })
      );
    },
    [dispatch, selectedRequestId, setSelectedRequestId]
  );

  const handleSearch = useCallback(
    (searchQuery: string) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchQuery,
      }));
    },
    [setFilters]
  );

  const handleFiltersChange = useCallback(
    (values: any) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...values,
      }));
    },
    [setFilters]
  );

  return (
    <Row className={styles.actionBar}>
      <Col sm={4}>
        <Button
          label="Approve"
          disabled={disabledAction}
          onClick={handleActionClick(RequestAction.APPROVE)}
        />
        <Button
          label="Decline"
          disabled={disabledAction}
          onClick={handleActionClick(RequestAction.DECLINE)}
        />
      </Col>
      <Col sm={8} className="oui-d-flex oui-justify-content-end">
        <div>
          <Input
            onChange={(e) => handleSearch(e.target.value || "")}
            className={styles.searchInput}
            type="search"
            placeholder="Search by Request ID or MSP Name or Account Name"
            value={filters.searchQuery}
            showClearIcon={!!filters.searchQuery}
            onClear={() => handleSearch("")}
          />
        </div>
        <FilterButton
          filterTitle="Filters"
          popoverClassName="oui-p-1 oui-pb-3"
          className="oui-ml-3"
          filters={filterOptions}
          renderFilters={(formikProps) => (
            <FilterRenderer formikProps={formikProps} />
          )}
          onSubmit={handleFiltersChange}
        />
      </Col>
    </Row>
  );
};

export default ActionBar;
