import { FC } from "react";
import LabelValue from "orion/lib/labelValue";
import CrossRedOutline from "@druva-apps/orion-icons/lib/CrossRedOutline";
import TickGreenOutline from "@druva-apps/orion-icons/lib/TickGreenOutline";

interface ActivationIndicatorProps {
  isActive: boolean;
  label: string;
}

const ActivationIndicator: FC<ActivationIndicatorProps> = ({
  isActive,
  label,
}) => {
  return (
    <LabelValue
      label={label}
      value={isActive ? "Enabled" : "Disabled"}
      type="summary"
      icon={isActive ? <TickGreenOutline /> : <CrossRedOutline />}
    />
  );
};

export default ActivationIndicator;
