import React, { FC } from "react";
import Field from "components/Field";
import {
  RequestAction,
  RequestActionFormContainerProps,
} from "./edu-license-requests.types";

const RequestActionFormContainer: FC<RequestActionFormContainerProps> = ({
  formikProps,
  action,
}) => {
  return (
    <div>
      {/* @ts-expect-error - types not correct in orion */}
      <Field
        label={`Reason for ${
          action === RequestAction.APPROVE ? "approval" : "declining"
        }`}
        name="comment"
        formikProps={formikProps}
        full
        type="textarea"
      />
      {/* @ts-expect-error - types not correct in orion */}
      <Field
        label={`Type '${
          action === RequestAction.APPROVE ? "Approve" : "Decline"
        }' to confirm`}
        name="confirmation"
        formikProps={formikProps}
      />
    </div>
  );
};

export default RequestActionFormContainer;
