import Page from "orion/lib/page";
import { FC, useDeferredValue, useMemo, useState } from "react";

import useEduRequests from "queryHooks/useEduRequests/useEduRequests";
import { TMspEduRequest } from "types/apis/edu-requests";
import {
  EduRequestStatus,
  FilterListState,
} from "./edu-license-requests.types";
import ActionBar from "./requests-action-bar";
import RequestsList from "./requests-list";
import {
  matchProduct,
  matchSearchQuery,
  matchStatus,
  sortRequests,
} from "./requests.helper";

const EduLicenseRequests: FC = () => {
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null
  );
  const [filters, setFilters] = useState<FilterListState>({
    searchQuery: "",
    product: [],
    status: [],
    sortBy: "status",
    sortOrder: "desc",
  });

  const deferredSearchQuery = useDeferredValue(filters.searchQuery);

  const { eduRequests, canFetchMore, isFetching } = useEduRequests();

  const filteredList = useMemo(
    () =>
      eduRequests
        .filter((request) => {
          return (
            matchSearchQuery(request, deferredSearchQuery) &&
            matchStatus(request, filters.status) &&
            matchProduct(request, filters.product)
          );
        })
        .sort(sortRequests(filters)),
    [eduRequests, filters, deferredSearchQuery]
  );

  return (
    <Page.Main>
      <Page.Header>
        {/*  @ts-expect-error Orion Types does not support this */}
        <Page.Title>
          {/*  @ts-expect-error Orion Types does not support this */}
          <Page.TitleText>Education License Requests</Page.TitleText>
        </Page.Title>
      </Page.Header>
      {/*  @ts-expect-error Orion Types does not support this */}
      <Page.Body>
        <ActionBar
          selectedRequestId={selectedRequestId}
          setSelectedRequestId={setSelectedRequestId}
          setFilters={setFilters}
          filters={filters}
        />
        <RequestsList
          data={filteredList as unknown as TMspEduRequest[]}
          canFetchMore={canFetchMore}
          isFetching={isFetching}
          setSelectedRequestId={setSelectedRequestId}
          selectedRowId={selectedRequestId}
          disabledRequests={eduRequests
            .filter(({ status }) => EduRequestStatus.PENDING !== status)
            .map(({ id }) => id)}
          setFilters={setFilters}
          filters={filters}
        />
      </Page.Body>
    </Page.Main>
  );
};

export default EduLicenseRequests;
