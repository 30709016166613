import get from "lodash/get";
import Card from "orion/lib/card";
import Col from "orion/lib/col";
import LabelValue from "orion/lib/labelValue";
import Loader from "orion/lib/loader";
import Row from "orion/lib/row";
import React from "react";
import clsx from "clsx";

import { IMetaDataCardProps } from "./types";
import styles from "./meta-data-card.module.scss";

const MetaDataCard: React.FC<IMetaDataCardProps> = ({
  metadata,
  data,
  cardProps,
  renderHeader,
  isLoading,
  rowClassName,
  loadingText,
}) => {
  const { fields, layout } = metadata;

  const getBody = () => (
    <>
      {renderHeader?.()}
      {layout.groups.map((group, index, groups) => {
        const { title, key, children = [], renderFooter } = group;
        return (
          <div key={key}>
            {title && <Card.Title title={title} className="oui-mb-2" />}
            {children.length > 0 ? (
              <Row
                className={clsx(styles.metaDataCard, {
                  "oui-mt-2": rowClassName,
                })}
              >
                {children.map((fieldName) => {
                  const fieldProps = fields[fieldName];
                  return (
                    <Col
                      sm={fieldProps?.colSpan ?? "3"}
                      key={fieldName}
                      className={fieldProps?.colClassName ?? "oui-mb-1"}
                    >
                      {fieldName && (
                        <LabelValue
                          {...fieldProps}
                          value={
                            fieldProps?.valueGetter?.(
                              get(data, fieldName),
                              data
                            ) ?? get(data, fieldName, "-")
                          }
                          type="summary"
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            ) : null}
            {renderFooter?.()}
            {/* Add horizontal rule except for last group */}
            {index < groups.length - 1 && <hr />}
          </div>
        );
      })}
    </>
  );

  return (
    // @ts-expect-error - cardProps is not defined in the type of Orion
    <Loader isLoading={isLoading} text={loadingText}>
      <Card {...cardProps} body={getBody} />
    </Loader>
  );
};

export default MetaDataCard;
