import React from "react";
import { useIdleTimer } from "react-idle-timer";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { stringify } from "query-string";
import { Headerbar } from "orion";

import { showAlert } from "slices/alertsSlice";
import { LOGIN_REDIRECT_URL, cookies, genericErrorMessage } from "consts";
import useLogout from "queryHooks/useLogout/useLogout";
import useUser from "queryHooks/useUser/useUser";

const Navbar = ({ brandName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: userData } = useUser({
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
  });
  const { mutate: logout, isLoading } = useLogout({
    onSettled: (_data, e) => {
      if (e) {
        dispatch(
          showAlert({
            message:
              e?.data?.message ||
              "Unsuccessfully logout attempt. Redirecting...",
            type: "danger",
          })
        );
      }
      setTimeout(
        () => {
          cookies.remove("msps_refreshToken", {
            path: "/",
            secure: true,
            sameSite: "strict",
          });
          window.location = `${LOGIN_REDIRECT_URL}?${stringify({
            redirectUrl: window.location,
          })}`;
        },
        e ? 750 : 0
      );
    },
  });

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => {
      logout();
    },
    debounce: 500,
  });

  return (
    <Headerbar
      brand={{
        name: brandName,
        onClick: () => navigate("/"),
      }}
      right={[
        {
          component: "profile",
          data: {
            user: {
              ...(userData && {
                name: `${userData.firstName} ${userData.lastName}`,
                initials: `${userData.firstName.substr(
                  0,
                  1
                )}${userData.lastName.substr(0, 1)}`,
                email: userData.email,
              }),
            },
            links: [],
            logout: {
              onClick: (e) => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                !isLoading && logout();
              },
            },
          },
        },
      ]}
    />
  );
};

Navbar.propTypes = {
  brandName: PropTypes.string,
};

export default Navbar;
