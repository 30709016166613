import { FormikProps, FormikValues } from "formik";
import FormGroup from "orion/lib/formGroup";
import Input from "orion/lib/input";
import Label from "orion/lib/label";
import cloneDeep from "lodash/cloneDeep";

import { filterOptions } from "./requests.helper";
import { ChangeEvent, useCallback } from "react";

const FilterRenderer = ({
  formikProps,
}: {
  formikProps: FormikProps<FormikValues>;
}) => {
  const onChangeHandler = useCallback(
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      const valueArray = cloneDeep(formikProps.values[key] ?? []);
      const value = Number(target.value);
      if (target.checked) {
        valueArray.push(value);
      } else {
        valueArray.splice(valueArray.indexOf(value), 1);
      }
      formikProps.setFieldValue(key, valueArray, true);
    },
    [formikProps]
  );

  return filterOptions.map(({ title, filters, name }) => (
    <FormGroup key={name}>
      <Label text={title} muted />
      <div className="oui-d-flex oui-mb-4">
        {Object.entries(filters).map(([key, label]) => {
          return (
            <Input
              key={`${key}-${name}`}
              inline
              type="checkbox"
              id={`${key}-${name}`}
              name={name}
              value={Number(key)}
              label={label}
              validationProps={formikProps as any}
              onChange={onChangeHandler(name)}
            />
          );
        })}
      </div>
    </FormGroup>
  ));
};

export default FilterRenderer;
