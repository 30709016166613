import React, { FC } from "react";
import Tooltip from "orion/lib/tooltip";
import CrossRedOutline from "@druva-apps/orion-icons/lib/CrossRedOutline";
import TickGreenOutline from "@druva-apps/orion-icons/lib/TickGreenOutline";
import QueuedGreyOutline from "@druva-apps/orion-icons/lib/QueuedGreyOutline";

import { EduRequestStatus } from "./edu-license-requests.types";
interface StatusRendererProps {
  status: EduRequestStatus;
  comment: string;
}

export const statusNameMap: {
  [keyof in EduRequestStatus]: {
    name: string;
    icon: React.ReactNode;
  };
} = {
  [EduRequestStatus.PENDING]: {
    name: "Pending",
    icon: <QueuedGreyOutline />,
  },
  [EduRequestStatus.APPROVED]: {
    name: "Approved",
    icon: <TickGreenOutline />,
  },
  [EduRequestStatus.DECLINED]: {
    name: "Declined",
    icon: <CrossRedOutline />,
  },
  [EduRequestStatus.NOTREQUESTED]: {
    name: "Not Requested",
    icon: <div></div>,
  },
};

const StatusRenderer: FC<StatusRendererProps> = ({ status, comment }) => {
  return (
    <>
      <div className="oui-w-100">
        {statusNameMap[status].icon}
        <span className="oui-ml-2">{statusNameMap[status].name}</span>
      </div>
      <Tooltip text={comment} overflowOnly>
        <div className="oui-text-muted oui-text-truncate">{comment}</div>
      </Tooltip>
    </>
  );
};

export default StatusRenderer;
