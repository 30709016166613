import { MSP_ACCOUNT_STATE } from "consts";

/**
 * Converts a boolean value to a corresponding status string or a Yes/No string based on the specified mode.
 * @param {boolean} value - The boolean value indicating the status (enabled or disabled).
 * @param {boolean} isYesNo - If true, returns "Yes" for true and "No" for false. Otherwise, returns "Enabled" for true and "Disabled" for false.
 * @returns {string} The status string or Yes/No string based on the specified mode.
 */
export const getStatusString = (value, isYesNo = false) => {
  if (isYesNo) {
    return value ? "Yes" : "No";
  }
  return value ? "Enabled" : "Disabled";
};

/**
 * Updates the status of an MSP account based on its suspension state.
 * This is a backend tech debt that will be fixed from backend and then no transformation will be required.
 *
 * @param {Object} msp - The MSP account object.
 * @param {boolean} msp.isSuspended - Indicates if the MSP account is suspended.
 * @param {string} msp.status - The current status of the MSP account.
 * @returns {Object} The updated MSP account object with the new status.
 */
export const updateMspStatus = (msp) => {
  const { isSuspended, status, ...rest } = msp;
  return {
    ...rest,
    status:
      isSuspended && status !== MSP_ACCOUNT_STATE.markedForDeletion
        ? MSP_ACCOUNT_STATE.suspended
        : status,
  };
};
