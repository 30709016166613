import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { API_EDU_REQUESTS_UPDATE, genericErrorMessage, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";
import { pollTaskStatus } from "queryHooks/useTaskStatus/useTaskStatus";
import withUrlParams from "utils/withUrlParams";
import { showAlert } from "slices/alertsSlice";

export type TUpdateRequestPayload = {
  status: number;
  comment: string;
  id: string;
};

type TSuccessResponse = { warning: boolean; message: string };

export default function useUpdateEduRequest(
  config?: {
    onSuccess: (data: { warning: boolean; message: string }) => void;
  } & UseMutationOptions<TSuccessResponse, unknown, TUpdateRequestPayload>
) {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateEduRequest = ({ id, ...payload }: TUpdateRequestPayload) =>
    new Promise<TSuccessResponse>(async (resolve, reject) => {
      try {
        const res = await httpClient.patch(
          withUrlParams(API_EDU_REQUESTS_UPDATE, { id }),
          payload
        );
        pollTaskStatus(httpClient, res?.task?.id, resolve, reject, dispatch);
      } catch (err) {
        reject(err);
      }
    });

  return useMutation<
    TSuccessResponse,
    { data?: { message: string } },
    TUpdateRequestPayload
  >((payload) => updateEduRequest(payload), {
    ...config,
    onSuccess: (data) => {
      queryClient.resetQueries(QUERIES.getEduRequestList);
      config?.onSuccess?.(data);
    },
    onError: (e) => {
      dispatch(
        showAlert({
          message: e.data?.message || genericErrorMessage,
          type: "danger",
        })
      );
    },
  });
}
