import Loader from "orion/lib/loader";
import Page from "orion/lib/page";
import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Navbar from "shared/Navbar";
const Accounts = lazy(
  () => import(/* webpackChunkName: "accounts" */ "./Accounts/Accounts")
);
const Account = lazy(
  () => import(/* webpackChunkName: "account" */ "./Account/Account")
);
const EduLicenseRequests = lazy(
  () =>
    import(
      /* webpackChunkName: "edu-license-requests" */ "./edu-license-requests"
    )
);
const Sidebar = lazy(
  () => import(/* webpackChunkName: "sidebar" */ "shared/sidebar")
);

const App: FC = () => {
  return (
    <Suspense fallback={<Loader fullPage isLoading />}>
      <Navbar brandName="Managed Services Center Support Portal" />
      <Page>
        <Sidebar />
        <Routes>
          <Route path="accounts" element={<Accounts />} />
          <Route path="accounts/:accountId" element={<Account />} />
          <Route path="edu-license-requests" element={<EduLicenseRequests />} />
          <Route path="/" element={<Navigate replace to="accounts" />} />
        </Routes>
      </Page>
    </Suspense>
  );
};

export default App;
