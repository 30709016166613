import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { PAGE_SIZE } from "consts";
import { useHttpClient } from "contexts/httpClient";
import { AxiosInstance, AxiosResponse } from "axios";
import { useEffect } from "react";

interface FetchDataParams {
  httpClient: AxiosInstance;
  pageParam: string;
  dataKey: string;
  apiUrl: string;
}

interface FetchDataResult<T> {
  data: T;
  pageParam: string;
}

const fetchData = async <T>({
  httpClient,
  pageParam,
  dataKey,
  apiUrl,
}: FetchDataParams): Promise<FetchDataResult<T>> => {
  const response: AxiosResponse["data"] = await httpClient.get(apiUrl, {
    params: pageParam ? { pageToken: pageParam } : { pageSize: PAGE_SIZE },
  });

  return {
    data: response[dataKey],
    pageParam: response.nextPageToken,
  };
};

interface UseAutoFetchInfiniteQueryParams {
  dataKey: string;
  apiUrl: string;
  queryKeys: string[];
}

export function useAutoFetchInfiniteQuery<T>({
  dataKey,
  apiUrl,
  queryKeys,
}: UseAutoFetchInfiniteQueryParams): UseInfiniteQueryResult<T[], unknown> {
  const httpClient = useHttpClient();
  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery<
    FetchDataResult<T>,
    unknown
  >(
    queryKeys,
    ({ pageParam = "" }) =>
      fetchData<T>({ httpClient, pageParam, dataKey, apiUrl }),
    {
      getNextPageParam: ({ pageParam }) => pageParam || undefined,
      // @ts-expect-error - data is not defined in the type
      select: (data) => data?.pages?.flatMap((page) => page.data) || [],
      meta: {
        disableFullPageLoader: true,
      },
      staleTime: 1000 * 60 * 5,
    }
  );

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, fetchNextPage]);

  return { data, isFetching, hasNextPage } as UseInfiniteQueryResult<
    T[],
    unknown
  >;
}
