import { useAutoFetchInfiniteQuery } from "../../hooks/useAutoFetchInfiniteQuery";
import { API_EDU_REQUESTS, QUERIES } from "consts";
import { TMspEduRequest } from "types/apis/edu-requests";

const dataKey = "msp_edu_requests";

const useEduRequests = () => {
  const {
    data: eduRequests,
    hasNextPage,
    isFetching,
  } = useAutoFetchInfiniteQuery<TMspEduRequest>({
    dataKey,
    apiUrl: API_EDU_REQUESTS,
    queryKeys: [QUERIES.getEduRequestList],
  });

  const canFetchMore = hasNextPage === undefined || hasNextPage;

  return {
    eduRequests: (eduRequests || []) as TMspEduRequest[],
    isFetching,
    canFetchMore,
  };
};

export default useEduRequests;
