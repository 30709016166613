import HelpGreyOutline from "@druva-apps/orion-icons/lib/HelpGreyOutline";
import {
  MSP_ACCOUNT_STATE,
  PRODUCT_ID,
  dataSourceTypes,
  dateFormatter,
  productIDToName,
} from "consts";
import { Button, Card, Col, LabelValue, Row, Spinner, Tooltip } from "orion";
import useMSP from "queryHooks/useMSP/useMSP";
import useUpdateMSPPermission from "queryHooks/useUpdateMSPPermission/useUpdateMSPPermission";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { closeModal, openModal } from "slices/modalsSlice";
import { getStatusString } from "utils/formatter";
import "./Summary.scss";
import {
  UPDATE_PERMISSIONS_FORM_KEY,
  AllowedToDelete,
  AllowedToDeleteNow,
  AllowedToResetPassword,
  AllowedToResetPasswordAdminEmail,
  AllowedToResetPasswordExpiryDate,
  isEducationLicenseEnabled,
} from ".";
import { showAlert } from "slices/alertsSlice";
import { genericErrorMessage } from "consts";
import { formatInTimeZone } from "date-fns-tz";
import { useCallback } from "react";
import { asLocalTime } from "utils/timezone";
import ActivationIndicator from "components/ActivationIndicator";
import MetaDataCard from "shared/meta-data-card";

const Summary = () => {
  let { accountId } = useParams();
  const { data: mspData, isLoading: isMspLoading } = useMSP(accountId);
  const dispatch = useDispatch();

  const isEditAllowed = !(
    isMspLoading ||
    [
      MSP_ACCOUNT_STATE.creationFailed,
      MSP_ACCOUNT_STATE.markedForDeletion,
    ].includes(mspData?.status)
  );

  const { isLoading: isUpdating, mutate: updatePermission } =
    useUpdateMSPPermission(accountId, {
      onSuccess: () => {
        dispatch(closeModal(UPDATE_PERMISSIONS_FORM_KEY));
      },
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
    });

  const handleEditClick = useCallback(() => {
    dispatch(
      openModal({
        key: UPDATE_PERMISSIONS_FORM_KEY,
        mspId: accountId,
        initialVaue: {
          [AllowedToDelete]: mspData?.permissions?.[AllowedToDelete],
          [AllowedToDeleteNow]: mspData?.permissions?.[AllowedToDeleteNow],
          [AllowedToResetPassword]:
            mspData?.permissions?.[AllowedToResetPassword],
          [AllowedToResetPasswordAdminEmail]:
            mspData?.permissions?.[AllowedToResetPasswordAdminEmail],
        },
        updatePermission: updatePermission,
      })
    );
  }, [updatePermission, dispatch, accountId, mspData?.permissions]);

  return (
    <>
      <MetaDataCard
        cardProps={{
          title: "Summary",
        }}
        isLoading={isMspLoading}
        data={{
          organizationName: mspData?.organizationName,
          administratorEmail: mspData?.email,
          expiresOn: mspData?.expiryDate
            ? dateFormatter.format(asLocalTime(mspData?.expiryDate))
            : "-",
          mspId: mspData?.id,
          sfdcId: mspData?.sfdcId,
          licenseType: mspData?.edition,
        }}
        metadata={{
          fields: {
            organizationName: {
              // label: "Organization Name",
              // TODO: This should be Organization name and not customer name, will handle this in separate ticket and not in feature ticket
              label: "Customer Name",
              truncateValue: true,
            },
            administratorEmail: {
              label: "Administrator Email",
              truncateValue: true,
            },
            expiresOn: {
              label: "Expires On",
            },
            mspId: {
              label: "MSP ID",
              truncateValue: true,
            },
            sfdcId: {
              label: "SFDC ID",
              truncateValue: true,
            },
            licenseType: {
              label: "License Type",
              truncateValue: true,
              className: "oui-text-capitalize",
            },
          },
          layout: {
            groups: [
              {
                key: "summary",
                title: "",
                children: [
                  "organizationName",
                  "administratorEmail",
                  "expiresOn",
                  "mspId",
                  "sfdcId",
                  "licenseType",
                ],
              },
            ],
          },
        }}
      />
      <Row className="oui-mt-4 oui-mb-4">
        {mspData?.products?.map((details) => {
          const { productID, skus, storageRegions, datasources, features } =
            details;
          return (
            <Col sm="6" key={`license-card-${productID}`}>
              <Card
                title={productIDToName[productID]}
                fullHeight
                body={() => (
                  <>
                    <Row className="oui-mt-4 oui-mb-4">
                      <Col sm="6">
                        <LabelValue
                          label="Edition"
                          className="oui-text-capitalize"
                          value={skus.join(", ")}
                          truncateValue
                        />
                      </Col>
                      <Col sm="6">
                        <LabelValue
                          label="Storage Region"
                          className="oui-text-capitalize"
                          value={storageRegions.join(", ")}
                          truncateValue
                        />
                      </Col>
                    </Row>
                    {productID === PRODUCT_ID.saasAppsAndEndpoints ? (
                      <>
                        <Row>
                          <Col sm="6">
                            <LabelValue
                              label="Product"
                              className="oui-text-capitalize"
                              value={datasources
                                ?.map(
                                  (datasources) =>
                                    dataSourceTypes[datasources.name]
                                )
                                ?.join(", ")}
                              truncateValue
                            />
                          </Col>
                          <Col sm="6">
                            <ActivationIndicator
                              label="Education License"
                              isActive={isEducationLicenseEnabled(features)}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </>
                )}
              />
            </Col>
          );
        })}
      </Row>

      <MetaDataCard
        isLoading={isUpdating || isMspLoading}
        cardProps={{
          title: () => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Card.Title collapsible title="Advance Settings" />
              <Tooltip
                text="With this option enabled , you are letting MSP delete their customers"
                placement="right"
              >
                <HelpGreyOutline
                  width={12}
                  height={12}
                  className="oui-ml-2"
                  muted={false}
                />
              </Tooltip>
            </div>
          ),
          titleAction: () =>
            isEditAllowed && (
              <Button
                onClick={handleEditClick}
                label="Edit"
                dataTestId="advance-setting-edit-button"
              />
            ),
        }}
        data={{
          enableDeleteTenant: getStatusString(
            mspData?.permissions?.[AllowedToDelete]
          ),
          allowDeleteImmediately: getStatusString(
            mspData?.permissions?.[AllowedToDeleteNow]
          ),
          enableResetPassword: getStatusString(
            mspData?.permissions?.[AllowedToResetPassword]
          ),
          emailConfiguredForPasswordReset:
            mspData?.permissions?.[AllowedToResetPasswordAdminEmail] || "-",
          passwordResetAccessExpiry: mspData?.permissions?.[
            AllowedToResetPasswordExpiryDate
          ]
            ? `${formatInTimeZone(
                mspData?.permissions?.[AllowedToResetPasswordExpiryDate],
                "UTC",
                "MMM dd YYY HH:mm:ss"
              )} UTC`
            : "-",
        }}
        metadata={{
          layout: {
            groups: [
              {
                key: "advanceSettings",
                title: "",
                children: [
                  "enableDeleteTenant",
                  "allowDeleteImmediately",
                  "",
                  "",
                  "enableResetPassword",
                  "emailConfiguredForPasswordReset",
                  "passwordResetAccessExpiry",
                ],
              },
            ],
          },
          fields: {
            enableDeleteTenant: {
              label: "Enable Delete Tenant",
            },
            allowDeleteImmediately: {
              label: "Allow Delete Immediately",
            },
            enableResetPassword: {
              label: "Enable Reset Password",
            },
            emailConfiguredForPasswordReset: {
              label: "Email Configured for Password Reset",
            },
            passwordResetAccessExpiry: {
              label: "Password Reset Access Expiry",
            },
          },
        }}
      />
    </>
  );
};
export default Summary;
