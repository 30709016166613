export const AllowedToDelete = "allowedToDelete";
export const AllowedToDeleteNow = "allowedToDeleteNow";
export const AllowedToResetPassword = "allowedToResetPassword";
export const AllowedToResetPasswordAdminEmail =
  "allowedToResetPasswordAdminEmail";
export const AllowedToResetPasswordExpiryDate =
  "allowedToResetPasswordExpiryDate";

export const UPDATE_PERMISSIONS_FORM_KEY = "UPDATE_PERMISSIONS_FORM";

export const getValidationSchema = (Yup) =>
  Yup.object().shape({
    [AllowedToDelete]: Yup.boolean(),
    [AllowedToDeleteNow]: Yup.boolean(),
    [AllowedToResetPassword]: Yup.boolean(),
    [AllowedToResetPasswordAdminEmail]: Yup.string().when(
      AllowedToResetPassword,
      {
        is: true,
        then: Yup.string().email("Invalid Email").required("Email is required"),
      }
    ),
  });

export const isEducationLicenseEnabled = (features = []) => {
  const educationLicense = features.find(
    ({ name }) => name === "Education License"
  );
  if (!educationLicense) {
    return false;
  }
  return educationLicense.isEnabled;
};
