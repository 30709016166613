import { formatInTimeZone } from "date-fns-tz";
import { DataGridConfigProps } from "orion/lib/dataCollection";

import { dataSourceIdToName, SortOrder } from "consts";
import { TMspEduRequest } from "types/apis/edu-requests";
import { EduRequestStatus } from "./edu-license-requests.types";
import StatusRenderer, { statusNameMap } from "./status-renderer";

export const requestsColumnsDef = [
  {
    key: "id",
    title: "Request ID",
    dataKey: "id",
    flexGrow: 1,
    sortable: true,
    resizable: false,
    truncateText: true,
  },
  {
    key: "mspName",
    title: "MSP Name",
    dataKey: "mspName",
    flexGrow: 1,
    sortable: true,
    resizable: false,
    truncateText: true,
  },
  {
    key: "accountName",
    title: "Account Name",
    dataKey: "accountName",
    flexGrow: 1,
    sortable: true,
    resizable: false,
    truncateText: true,
  },
  {
    key: "dataSourceID",
    title: "Product",
    dataKey: "dataSourceID",
    flexGrow: 1,
    sortable: true,
    resizable: false,
    cellRenderer: (cellData: keyof typeof dataSourceIdToName) =>
      dataSourceIdToName[cellData],
  },
  {
    key: "createdOn",
    title: "Generated On",
    dataKey: "createdOn",
    flexGrow: 1,
    sortable: true,
    resizable: false,
    cellRenderer: (cellData: string) =>
      formatInTimeZone(cellData, "UTC", "MMM dd, YYY, HH:mm:ss"),
  },
  {
    key: "status",
    title: "Status",
    dataKey: "status",
    flexGrow: 1,
    sortable: true,
    resizable: false,
    className: "oui-flex-wrap",
    cellRenderer: (
      cellData: EduRequestStatus,
      dataKey: string,
      rowIndex: number,
      rowData: TMspEduRequest
    ) => <StatusRenderer status={cellData} comment={rowData?.comment || ""} />,
  },
] as any as DataGridConfigProps<any, any, any, any>["columnsDef"];

export const sortPendingRequestsFirst = (
  a: TMspEduRequest,
  b: TMspEduRequest
) => {
  // keep pending requests at the top
  if (
    a.status === EduRequestStatus.PENDING &&
    b.status !== EduRequestStatus.PENDING
  ) {
    return -1;
  }
  if (
    a.status !== EduRequestStatus.PENDING &&
    b.status === EduRequestStatus.PENDING
  ) {
    return 1;
  }
  return 0;
};

const keyNameMapper: {
  [key: string]: (value: any) => string;
} = {
  dataSourceID: (id: keyof typeof dataSourceIdToName) => dataSourceIdToName[id],
  status: (status: EduRequestStatus) => statusNameMap[status].name,
};

export const sortRequests =
  ({
    sortBy,
    sortOrder,
  }: {
    sortBy: keyof TMspEduRequest;
    sortOrder: SortOrder;
  }) =>
  (a: TMspEduRequest, b: TMspEduRequest) => {
    if (sortBy === "createdOn") {
      const dateA = new Date(a.createdOn).getTime();
      const dateB = new Date(b.createdOn).getTime();
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    }

    const aVal = (
      keyNameMapper.hasOwnProperty(sortBy)
        ? keyNameMapper[sortBy](a[sortBy])
        : a[sortBy] ?? ""
    )
      .toString()
      .toLowerCase();

    const bVal = (
      keyNameMapper.hasOwnProperty(sortBy)
        ? keyNameMapper[sortBy](b[sortBy])
        : b[sortBy] ?? ""
    )
      .toString()
      .toLowerCase();

    const comparison = aVal.localeCompare(bVal);
    return sortOrder === "asc" ? comparison : -comparison;
  };

export const matchSearchQuery = (
  request: TMspEduRequest,
  searchQuery: string
) => {
  if (searchQuery !== "") {
    const { mspName, accountName, id } = request;
    return [mspName, accountName, id.toString()].some((str) =>
      str.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  return true;
};

export const matchProduct = (request: TMspEduRequest, product: number[]) => {
  return product.length > 0 ? product.includes(request.dataSourceID) : true;
};

export const matchStatus = (request: TMspEduRequest, status: number[]) => {
  return status.length > 0 ? status.includes(request.status) : true;
};

export const filterOptions = [
  {
    name: "product",
    fieldName: "product",
    title: "Product",
    value: [],
    filters: {
      2: dataSourceIdToName[2],
      4: dataSourceIdToName[4],
    },
  },
  {
    fieldName: "status",
    name: "status",
    title: "Status",
    value: [],
    filters: {
      [EduRequestStatus.DECLINED]: "Declined",
      [EduRequestStatus.APPROVED]: "Approved",
      [EduRequestStatus.PENDING]: "Pending",
    },
  },
];
