import Bouncer from "orion/lib/bouncer";
import DataCollection from "orion/lib/dataCollection";
import Messagebox from "orion/lib/messageBox";
import { FC, useCallback, useMemo } from "react";

import { ReactComponent as TrayIcon } from "assets/icons/tray.svg";
import { TMspEduRequest } from "types/apis/edu-requests";
import {
  EduRequestStatus,
  RequestsListProps,
} from "./edu-license-requests.types";
import { requestsColumnsDef } from "./requests.helper";
import { SortOrder } from "consts";

const RequestsList: FC<RequestsListProps> = ({
  data = [],
  canFetchMore,
  isFetching,
  setSelectedRequestId,
  selectedRowId,
  disabledRequests,
  setFilters,
  filters,
}) => {
  const height = useMemo(
    () => (data.length > 0 ? "calc(100vh - 236px)" : "400px"),
    [data.length]
  );

  const onSort = useCallback(
    (sortBy: keyof TMspEduRequest, sortOrder: SortOrder) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy,
        sortOrder,
      }));
    },
    [setFilters]
  );

  return (
    <DataCollection
      dataGridConfig={
        {
          columnsDef: requestsColumnsDef,
          data: data || [],
          onSort: onSort,
          sortBy: filters.sortBy,
          sortOrder: filters.sortOrder,
          tableId: "edu-request-list-table",
          responsive: true,
          tableHeight: height,
          uniqueDataKey: "id",
          isSelectable: true,
          multiSelect: false,
          disabledRows: disabledRequests,
          totalRowsCount: data.length,
          loadingMoreText: "Loading...",
          isLoadingMore: canFetchMore,
          onRowClick: ({ id, status }: TMspEduRequest) => {
            if (status === EduRequestStatus.PENDING) {
              setSelectedRequestId(id);
            }
          },
          selectedRow: selectedRowId,
          emptyRenderer: () =>
            isFetching ? null : (
              <Messagebox
                icon={<TrayIcon height="56px" width="56px" />}
                primaryText="No requests found"
              />
            ),
          renderCustomListCountWrapper: () => (
            <div className="oui-overflow-hidden">
              Requests
              <span className="oui-dg-list-wrapper-data-separator"></span>
              {canFetchMore ? (
                <Bouncer
                  size="md"
                  // @ts-expect-error - Orion types are not up-to-date
                  className="oui-d-inline-block oui-ml-1"
                  gray
                />
              ) : (
                data.length
              )}
            </div>
          ),
        } as any
      }
    />
  );
};

export default RequestsList;
